<template>
  <div>1123</div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    console.log(this.$route.query)
    this.vs = this.$route.query
    this.$axios.get('api/mengshibifenodds', {
      params: this.$route.query
    }).then(res => {
      console.log(res.data)
      const temp = res.data
      const tableData = [null, null, null, null, null, null, null, null, null]
      if (temp.errcode === -1) {
        this.$router.push({ path: '/login' })
      } else if (temp.errcode > 0) {
        this.errMsg = temp.msg
      } else if (temp.errcode === 0) {
        this.realTime = temp.realTime
        // const tableData = [null, null, null, null, null, null, null, null, null]
        for (let index = temp.datas.length - 1; index >= 0; index--) {
          console.log(temp.datas[index])
          if (this.companies[temp.datas[index].companyId] === undefined) {
            console.log(temp.datas[index].companyId)
            temp.datas[index].companyId = 9999
          } else {
            if (this.companies[temp.datas[index].companyId].sort !== 1000) {
              tableData[this.companies[temp.datas[index].companyId].sort - 1] = temp.datas.splice(index, 1)[0]
              continue
            }
            for (const key in temp.datas[index]) {
              if (!temp.datas[index][key]) {
                temp.datas.splice(index, 1)
                break
              }
            }
          }
        }
        for (let index = tableData.length - 1; index >= 0; index--) {
          if (tableData[index] === null) {
            tableData.splice(index, 1)
            continue
          }
          for (const key in tableData[index]) {
            if (!tableData[index][key]) {
              tableData.splice(index, 1)
              break
            }
          }
        }
        tableData.push(...temp.datas)
        this.tableData = tableData
        // console.log(tableData)
      }
    }).catch(err => {
      console.error(err)
    })
  }
}
</script>
